import {Component} from '@angular/core';
import {RoutingService} from "./services/routing.service";
import {NavigationEnd, Router} from "@angular/router";
import {Account} from "./api/models/account";
import {AuthService} from "./shared/auth/auth.service";
import {AccountService} from "./api/services/account.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'RatuVocer';

  constructor(
    private routingService: RoutingService,
    private router: Router,
    private authService: AuthService,
    private accountService: AccountService,
  ) {
    routingService.loadRouting();
    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd && this.authService.isLoggedIn) {
        this.accountService.account().subscribe({
          next: (res: Account) => {

          },
          error: (err) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('expires_at');
            window.location.reload()
          },
        });
      }
    });
  }
}
